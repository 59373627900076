export const routes = {
  home: "/",
  projects: "/#projects",
  partners: "/#Partners",
  investors: "/Investors",
  contact: "/#ContactUs",

  insights: "/insights",
  article1: "/insights/crafting-nft-marketplace",
  article2: "/insights/nft-marketplace-revenue",
  article3: "/insights/migrate-to-web3",
  article4: "/insights/secure-data-sharing",
  article5: "/insights/ai-customer-experience",
  article6: "/insights/ai-conversation",
  article7: "/insights/business-growth-with-ai",

  team: "/team",
  anas: "/team/anas-madi",
  mathew: "/team/matthew-connelly",

  ai: "/ai",
  aiConsulting: "/ai/consulting",
  machineLearning: "/ai/machine-learning",
  chatbot: "/ai/chatbot",
  mvpPrototype: "/ai/mvp-prototype",
  aiCustomSolution: "/ai/custom-solution",
  nlp: "/ai/nlp",

  blockchain: "/blockchain",
  nft: "/blockchain/nft",
  smartContract: "/blockchain/smart-contract",
  icp: "/blockchain/icp",
  cryptoWallet: "/blockchain/wallet",
  metaverse: "/blockchain/metaverse",
  whiteLabel: "/blockchain/white-label",
  blockchainConsulting: "/blockchain/consulting",

  softwareDevelopment: "/software",
  itConsulting: "/software/consulting",
  customSoftware: "/software/custom-software",
  web: "/software/web",
  mobile: "/software/mobile",
  recruitment: "/software/recruitment",
};
