import { routes } from "./navlink.config";
export interface Route {
  header: string;
  link?: string;
  id: string;
  section?: boolean;
  children?: Route[];
}

const routesStructure: Route[] = [
  {
    header: "Projects",
    link: routes.projects,
    id: "Projects",
    section: true,
  },
  {
    header: "Team",
    id: "Team",
    // section: true,
    link: routes.team,
    children: [
      {
        header: "Matthew Connelly",
        link: routes.mathew,
        id: "mathew",
      },
      {
        header: "Anas Madi",
        link: routes.anas,
        id: "anas",
      },
    ],
  },
  {
    header: "Blockchain",
    link: routes.blockchain,
    id: "blockchain",
  },
  {
    header: "Software Development",
    link: routes.softwareDevelopment,
    id: "software",
  },
  {
    header: "AI Integration",
    link: routes.ai,
    id: "ai",
  },

  {
    header: "Insights",
    link: routes.insights,
    id: "insights",
  },
];

export default routesStructure;
