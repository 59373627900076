import React, { Dispatch, SetStateAction, useReducer, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Dropdown } from "react-bootstrap";
import Routes, { Route } from "../../../../configs/Routes";
import Link from "next/link";
import Location from "./Location";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";

function RouteGenerator({
  setMobileClassName,
}: {
  setMobileClassName?: Dispatch<SetStateAction<string>>;
}) {
  const reducer = (state: Object, action: { type: string; id: string }) => {
    switch (action.type) {
      case "SHOW":
        return { ...state, [action.id]: true };
      case "HIDE":
        return { ...state, [action.id]: false };
      default:
        return state;
    }
  };

  const [dropdowns, dispatch] = useReducer(reducer, {});
  const [menuItemClass, setMenuItemClass] = useState("");
  const [menuItemContent, setmenuItemContent] = useState<any>({});
  const showDropdown = (id: string) => dispatch?.({ type: "SHOW", id });
  const hideDropdown = (id: string) => dispatch?.({ type: "HIDE", id });

  const handleMenuItemOpen = (route: Route) => {
    console.log("shitting", route);
    setMenuItemClass("menu-item-open");
    setmenuItemContent({
      title: route.header,
      links: route.children,
    });
  };
  const handleMenuItemClose = () => {
    setMenuItemClass("");
  };
  return Routes.map((route: Route, ix: number) => {
    return route.children ? (
      <React.Fragment key={route.id + ix}>
        <div
          className='mobile-navbar-child d-flex justify-content-between align-items-center d-lg-none'
          onClick={() => handleMenuItemOpen(route)}
        >
          <p className='title m-0'>{route.header}</p>
          <BsThreeDots className='menu-dots' />
        </div>
        <div className={`menu-item-default ${menuItemClass}  d-lg-none`}>
          <div className='d-flex justify-content-between p-4 align-items-center'>
            {menuItemContent.title === "Products" ? (
              <Link href='/products'>
                <h4>{menuItemContent.title}</h4>
              </Link>
            ) : menuItemContent.title === "Services" ? (
              <Link href='/services'>
                <h4>{menuItemContent.title}</h4>
              </Link>
            ) : (
              <h4>{menuItemContent.title}</h4>
            )}
            <AiOutlineClose
              onClick={handleMenuItemClose}
              className='cursor-pointer text-lg text-white'
            />
          </div>
          {menuItemContent.links?.map(
            ({ id, link, header }: { id: string; link: string; header: string }) => (
              <Link
                key={id + link}
                href={link}
                className='nav-link p-4'
                onClick={() => {
                  setMobileClassName?.("");
                }}
              >
                {header}
              </Link>
            ),
          )}
        </div>

        <Dropdown
          key={route.id}
          className='top-menue d-none d-lg-block '
          onMouseEnter={() => showDropdown(route.id)}
          onMouseLeave={() => hideDropdown(route.id)}
          show={dropdowns?.[route.id as keyof typeof dropdowns] as unknown as boolean}
        >
          <Dropdown.Toggle
            variant='outline-secondary'
            className='top-menue-title'
            href={route.link}
          >
            {route.header}
          </Dropdown.Toggle>
          <Dropdown.Menu className='DropdownMenu'>
            {route.children.map(({ id, link, header }) => (
              <Dropdown.Item as={Link} href={link} key={id} className='nav-link'>
                {header}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </React.Fragment>
    ) : (
      <React.Fragment key={route.id}>
        <Nav
          onClick={() => {
            setMobileClassName?.("");
          }}
        >
          {route.section ? (
            <Location route={route} />
          ) : (
            <Link className='nav-link' href={route.link as string} key={route.header}>
              {route.header}
            </Link>
          )}
        </Nav>
      </React.Fragment>
    );
  });
}
export default RouteGenerator;
