import Head from "next/head";

export const MetaTags = ({
  title,
  description,
  keywords,
  faqSchemaEntities,
  searchSchema,
  organizationSchema,
  image = "https://mcti.io/assets/images/website-img.png",
  type,
}: {
  title: string;
  description: string;
  keywords?: string;
  faqSchemaEntities?: any;
  searchSchema?: any;
  organizationSchema?: any;
  image?: string;
  type?: string;
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name='description' content={description} />
      {keywords ? <meta name='keywords' content={keywords} /> : null}
      <meta name='viewport' content='width=device-width, initial-scale=1' />

      {/*Start schema.org for google meta*/}
      <meta itemProp='name' content={title} />
      <meta itemProp='description' content={description} />
      <meta itemProp='image' content={image} />
      {/*End schema.org for google meta*/}

      {/*Start Facebook meta*/}
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      <meta property='og:type' content={type} />
      <meta property='og:url' content='https://mcti.io/' />
      <meta property='og:site_name' content='metachain' />
      {/*End Facebook meta*/}

      {/*Start Twitter meta*/}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@MetachainInc' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image} />
      {/*End Twitter meta*/}

      {organizationSchema ? (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(organizationSchema),
          }}
        ></script>
      ) : null}

      {searchSchema ? (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(searchSchema),
          }}
        ></script>
      ) : null}

      {faqSchemaEntities ? (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: faqSchemaEntities,
            }),
          }}
        ></script>
      ) : null}
    </Head>
  );
};
