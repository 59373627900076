import { Route } from "@/configs/Routes";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

function Location({ route }: { route: Route }) {
  const location = useRouter();

  if (location.pathname === "/") {
    return (
      <a href={route.link} key={route.header} className='nav-link'>
        {route.header}
      </a>
    );
  } else {
    return route.link ? (
      <Link
        className='nav-link'
        href={route.link}
        // end
        key={route.header}
      >
        {route.header}
      </Link>
    ) : (
      <a className='nav-link' key={route.header}>
        {route.header}
      </a>
    );
  }

  // Render something else if on a different page
}

export default Location;
