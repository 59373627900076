import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React from "react";
import { useEffect, useState } from "react";
import RouteGenerator from "./routes/RouteGenerator";
import { HiBars3 } from "react-icons/hi2";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import LogoSVG from "@/assets/img/logo.svg";
import MobileLogoSVG from "@/assets/img/logo-mob.svg";
import { AiOutlineClose } from "react-icons/ai";

function Header({
  transparent,
  withContactNavigation,
  withPadding,
}: {
  transparent?: boolean;
  withContactNavigation?: boolean;
  withPadding?: boolean;
}) {
  const router = useRouter();
  const [headerClassName, setHeaderClassName] = useState("");
  const [mobileClassName, setMobileClassName] = useState("");
  const handleScroll = (headerClassName: string) => {
    const PAGE_Y_OFFSET = 100;
    if (headerClassName !== "menuscroll" && window.pageYOffset >= PAGE_Y_OFFSET) {
      setHeaderClassName("menuscroll");
    } else if (headerClassName === "menuscroll" && window.pageYOffset < PAGE_Y_OFFSET) {
      setHeaderClassName("");
    }
  };
  useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]);

  const handleMobileNavbarOpen = () => {
    setMobileClassName("mobile-navbar-in");
  };
  const handleMobileNavbarClose = () => {
    setMobileClassName("");
  };

  return (
    <>
      {headerClassName ? <div className=' h-70px' /> : null}
      <Row
        className={`justify-content-center header-top ${
          withPadding && !headerClassName && "lg-px-30"
        } ${headerClassName} ${transparent && !headerClassName && "bg-transperant"}`}
      >
        <Col md={12} lg={10} className='d-lg-none '>
          <Navbar expand='lg' sticky='top'>
            <Navbar.Brand className='d-flex align-items-center'>
              <Navbar.Toggle
                aria-controls='basic-navbar-nav'
                id='id_ToggleBtn_mobile'
                onClick={handleMobileNavbarOpen}
                className='mx-2'
              >
                <HiBars3 />
              </Navbar.Toggle>
              <Link href='/'>
                <Image
                  id='id_logoMobile'
                  src={MobileLogoSVG}
                  title='Metachain technologies logo, Gateway to Innovation'
                  alt={`Metachain's logo symbolizes innovation in AI, blockchain, and the Metaverse. The future of software development solutions`}
                  className='logo-mobile d-block d-lg-none'
                  width={62.5}
                  height={40}
                />
              </Link>
            </Navbar.Brand>

            <div className={`mobile-navbar ${mobileClassName} d-lg-none`}>
              <div className='d-flex justify-content-between p-4 align-items-center'>
                <h4 className='mb-0'>All</h4>
                <AiOutlineClose
                  onClick={handleMobileNavbarClose}
                  className='cursor-pointer text-lg text-white'
                />
              </div>
              <RouteGenerator setMobileClassName={setMobileClassName} />
            </div>

            <Nav>
              <a
                href='#ContactUs'
                className='custom-primary btn btn-primary cursor-pointer'
                id='UpperContactUs'
              >
                Contact Us
              </a>
            </Nav>
          </Navbar>
        </Col>
        <Col md={12} lg={10} className='d-none d-lg-block '>
          <Navbar expand='lg' sticky='top'>
            <Navbar.Brand>
              <Link href='/'>
                <Image
                  id='id_logo'
                  src={LogoSVG}
                  title="Metachain technologies logo, together we'll build the future"
                  alt={`we are a software development company that specializes in AI, blockchain, and the Metaverse. We are the gateway to innovation`}
                  className='logo d-none d-lg-block'
                  width={140}
                  height={28.77}
                />
                <Image
                  id='id_logoMobile'
                  src={MobileLogoSVG}
                  alt={`Metachain's mobile logo symbolizes innovation in AI, blockchain, and the Metaverse.`}
                  title={`our logo is a symbol of innovation in AI, blockchain, and the Metaverse. We make the innovation`}
                  className='logo-mobile d-block d-lg-none'
                  width={150}
                  height={32}
                />
              </Link>
            </Navbar.Brand>

            <Navbar.Toggle
              aria-controls='basic-navbar-nav'
              id='id_ToggleBtn'
              onClick={handleMobileNavbarOpen}
            >
              <HiBars3 />
            </Navbar.Toggle>
            <div className={`mobile-navbar ${mobileClassName} d-lg-none`}>
              <div className='d-flex justify-content-between p-4 align-items-center'>
                <h4>All</h4>
                <AiOutlineClose onClick={handleMobileNavbarClose} className='cursor-pointer' />
              </div>
              <RouteGenerator setMobileClassName={setMobileClassName} />
            </div>

            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav>
                <RouteGenerator />
                <a
                  href='#ContactUs'
                  className='custom-primary d-none d-lg-block btn btn-primary cursor-pointer my-auto'
                  id='UpperCOntactUs'
                  onClick={() => {
                    if (withContactNavigation) {
                      router.push("/#ContactUs");
                    }
                  }}
                >
                  Contact Us
                </a>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
    </>
  );
}

export default Header;
